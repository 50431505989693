<template>
    <teacher v-if="isTeacher" />
    <student v-else />
</template>

<script>
import teacher from "./components/teacher.vue";
import student from "./components/student.vue";
export default {
    components: {
        teacher,
        student,
    },
};
</script>