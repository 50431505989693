<!-- 组件说明 -->
<template>
    <div class="home_work" v-loading="loading">
        <div class="ls_flex_between">
            <div class="ls_flexalign_center ls_flex_wrap search_part">
                <el-date-picker v-model="query.dateRange" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" size="medium"
                    style="width: 380px;"></el-date-picker>

                <el-select v-model="query.status" size="medium" style="width: 110px;">
                    <el-option v-for="item in statusOptions" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
                <el-select v-model="query.groupId" placeholder="全部标签" size="medium" style="width: 110px;">
                    <el-option v-for="dict in courseList" :key="dict.id" :label="dict.name" :value="dict.id" />
                </el-select>
                <el-input v-model.trim="query.competitionName" size="medium" placeholder="请输入比赛名称"
                    style="width: 200px;"></el-input>

                <el-button size="medium" plain @click="(query.pageIndex = 1), getList()" style="width: 80px;">
                    查询
                </el-button>
            </div>

            <el-button size="medium" type="success" @click="toEditRace()">
                创建比赛
            </el-button>
        </div>
        <div class="work_list" v-if="total > 0">
            <el-table ref="multipleTable" :data="workList" highlight-current-row
                :header-cell-style="{ background: '#FAFAFA' }" style="width: 100%">
                <el-table-column label="比赛名称">
                    <template slot-scope="scope">
                        <span :title="scope.row.competitionName" class="written">
                            {{ scope.row.competitionName }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="参加对象">
                    <template slot-scope="scope">
                        <span :title="scope.row.groupNames.join(',')">
                            {{ scope.row.groupNames | classRange }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column label="已提交" width="70px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0">--</span>
                        <span v-else>{{ scope.row.submitCount }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="已评分" width="70px">
                    <template slot-scope="scope">
                        <span>{{ scope.row.scoreCount }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="比赛开始时间" width="160px">
                    <template slot-scope="scope">
                        <span>
                            {{
                                scope.row.competitionStartTime
                                | formatDate("yyyy-MM-dd HH:mm:ss")
                            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="比赛结束时间" width="160px">
                    <template slot-scope="scope">
                        <span>
                            {{
                                scope.row.competitionEndTime
                                | formatDate("yyyy-MM-dd HH:mm:ss")
                            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="80px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0">未发布</span>
                        <span v-if="scope.row.status == 1">已发布</span>
                        <span v-if="scope.row.status == 2">进行中</span>
                        <span v-if="scope.row.status == 3">已结束</span>
                        <span v-if="scope.row.status == 4">已评分</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200px">
                    <template slot-scope="scope">
                        <div class="opt_part">
                            <template v-if="scope.row.status == 0">
                                <span @click="publish(scope.row)">发布</span>
                                <span @click="toEditRace(scope.row.id)">
                                    编辑
                                </span>
                                <span @click="del(scope.row)">删除</span>
                            </template>
                            <template v-if="scope.row.status == 1 || scope.row.status == 2">
                                <span @click="stop(scope.row)">撤回</span>
                            </template>
                            <template v-if="scope.row.status == 3">
                                <span @click="$router.push({ path: '/race/score?id=' + scope.row.id })">
                                    评分
                                </span>
                                <span @click="$router.push({ path: '/race/data?id=' + scope.row.id })">
                                    数据分析
                                </span>
                            </template>
                            <template v-if="scope.row.status == 4">
                                <span @click="$router.push({ path: '/race/data?id=' + scope.row.id })">
                                    数据分析
                                </span>
                            </template>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="total == 0 && !loading" class="no_data">
            <div class="empty">
                <i class="iconfont icon-queshengye_zanwushuju"></i>
            </div>
            <p class="tc">暂无数据</p>
        </div>
        <div class="page_part">
            <el-pagination v-show="total > 0" :current-page="query.pageIndex" :page-sizes="[10, 15, 20, 25]"
                :page-size="query.pageSize" :total="total" background layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    data () {
        return {
            query: {
                dateRange: [],
                status: "",
                competitionName: "", // 比赛名称

                groupId: "", // 班级id
                startTime: "",
                endTime: "",
                pageIndex: 1,
                pageSize: 10,
            },
            statusOptions: [
                { value: '', label: "全部" },
                { value: 0, label: "未发布" },
                { value: 1, label: "已发布" },
                { value: 2, label: "进行中" },
                { value: 3, label: "已结束" },
                { value: 4, label: "已评分" },
            ],
            courseList: [],
            workList: [],
            total: null,
            loading: false,
        };
    },
    mounted () {
        let nowTime = dayjs(new Date());
        this.query.dateRange = [
            nowTime.subtract(3, "month").format("YYYY-MM-DD HH:mm:ss"),
            nowTime.format("YYYY-MM-DD HH:mm:ss"),
        ];
        this.loading = true;
        this.$ajax.post("/user/group/getList", {}).then(response => {
            this.courseList = response.data;
            this.courseList.unshift({ name: "全部标签", id: "" });
            this.getList();
        });
    },
    filters: {
        classRange: function (value) {
            if (value == "") return "";
            if (value.length <= 2) {
                return value.join(",");
            } else {
                return value[0] + "," + value[1] + "...";
            }
        },
    },
    methods: {
        handleSizeChange (val) {
            this.query.pageIndex = 1;
            this.query.pageSize = val;
            this.getList();
        },
        handleCurrentChange (val) {
            this.query.pageIndex = val;
            this.getList();
        },
        getList () {
            this.loading = true;
            this.$ajax
                .post("/competition/queryList", {
                    ...this.query,
                    startTime: this.query.dateRange && this.query.dateRange[0],
                    endTime: this.query.dateRange && this.query.dateRange[1],
                })
                .then(response => {
                    var pageTotal = Math.ceil(
                        response.total / this.query.pageSize
                    );
                    if (pageTotal == 0) {
                        this.query.pageIndex = 1;
                    }
                    if (
                        this.query.pageIndex > pageTotal &&
                        this.query.pageIndex != 1
                    ) {
                        this.query.pageIndex = pageTotal;
                        this.getList();
                    } else {
                        // response.rows[0].status =5;
                        this.workList = response.list;
                        this.total = response.total;
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        del (row) {
            this.$confirm("删除比赛后将无法恢复", "确认删除比赛？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                this.$ajax.get("/competition/delete?id=" + row.id).then(res => {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    this.getList();
                });
            });
        },
        stop (row) {
            this.$confirm("撤回后学生将无法看到该比赛", "确认撤回比赛？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                this.$ajax.get("/competition/revocation?id=" + row.id).then(res => {
                    this.$message({
                        message: "撤回成功",
                        type: "success",
                    });
                    this.getList();
                }).catch(err => { });
            });
        },
        publish (row) {
            this.$confirm("您确定发布比赛吗？", "温馨提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                this.$ajax.get("/competition/publish?id=" + row.id).then(res => {
                    this.$message({
                        message: "发布成功",
                        type: "success",
                    });
                    this.getList();
                }).catch(err => { });
            });
        },

        toEditRace (id = "") {
            this.$router.push({ path: "/race/editRace?id=" + id });
        },
    },
};
</script>

<style lang="scss" scoped>
.a_color {
    color: rgb(105, 191, 248);
    cursor: pointer;
}

.search_part {
    >* {
        +* {
            margin-left: 7px;
        }

        &:first-child(2),
        &:nth-child(3) {
            width: 120px;
        }

        &:nth-child(4) {
            width: 200px;
        }

        &:nth-child(7) {
            width: 220px;
        }
    }
}

.vert {
    vertical-align: top;
}

.home_work {
    padding: 60px 30px 0 0;

    //   padding-top: 40px;
    .select-bnt {
        display: inline-block;
        width: 86px;
        height: 32px;
        margin-right: 20px;
        cursor: pointer;
        background: #ffffff;
        // border-radius: 6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        color: #999999;
        text-align: center;

        &.is-active {
            color: #f56c0b;
            border: 1px solid #f56c0b;
        }
    }
}

.work_list {
    margin-top: 20px;
    width: 100%;
    height: auto;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}

.no_data {
    text-align: center;

    .empty {
        margin-top: 60px;
        margin-bottom: 24px;

        i {
            font-size: 130px;
            color: #999;
        }
    }

    p {
        font-size: 16px;
        color: #999999;
        margin-bottom: 139px;
    }
}

.page_part {
    height: 25px;
    margin-bottom: 10px;
    margin-top: 15px;
    padding: 10px 20px !important;
    text-align: center;
}

.ad_btn {
    display: inline-block;
    width: 96px;
    height: 96px;
    border: 1px solid #cccccc;
    cursor: pointer;
    text-align: center;

    .el-icon-plus {
        font-size: 25px;
        font-weight: bold;
        line-height: 100px;
        color: #cccccc;
    }
}

.d_class {
    .el-dialog__body {
        padding: 10px;
    }
}

.material_part {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 16px;
    text-align: center;
    vertical-align: top;

    &.c_list {
        width: 96px;
        margin-right: 11px;
    }

    .img_card {
        width: 96px;
        height: 96px;
        position: relative;
        border: 1px solid #cccccc;
        margin: auto;

        .check {
            position: absolute;
            width: 20px;
            height: 20px;
            border: 1px solid #cccccc;
            border-right: none;
            border-top: none;
            right: 0;
            top: 0;
        }

        .close {
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: #000000;
            right: 0;
            top: 0;
            cursor: pointer;

            i {
                font-weight: bold;
                font-size: 12px;
                text-align: center;
                line-height: 16px;
                color: #ffffff;
                margin-left: 2px;
            }
        }

        .a_show {
            display: none;
            line-height: 20px;
        }

        .active {
            background-color: #8bb229;
            color: #ffffff;

            .a_show {
                display: block;
            }
        }

        img {
            width: 70px;
            height: 70px;
            margin-top: 10px;
        }
    }
}

.t_btn {
    background: rgba(218, 218, 218, 0.39);
    border-radius: 4px;
    padding: 8px 24px;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid rgba(250, 250, 250, 0.39);
    cursor: pointer;

    &:hover {
        border: 1px solid #8bb229;
        color: #8bb229;
    }

    &.active {
        border: 1px solid #8bb229;
        color: #8bb229;
    }
}

.written {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}</style>
